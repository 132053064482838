<template>
  <div>
    <script type="application/javascript" src="https://www.google.com/recaptcha/api.js?render=explicit" async defer></script>
    <div 
      id="g-recaptcha"
      ref="recaptcha"
    >
    </div>  
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'RecaptchaV2',
    props: ['callback'],
    data() {
      return {
        SITE_KEY: this.$store.state.recaptchaSiteKey,
        widget: 0,
      }
    },
    methods: {
      execute() {
        window.grecaptcha.execute(this.widget)
      },
      render() {
        let element = this;
        this.widget = window.grecaptcha.render(
          'g-recaptcha',
          {
            sitekey: this.SITE_KEY,
            size: 'invisible',
            callback: (token) => {
              element.callback(token);
              element.reset();
            } 
          }
        )
      },
      reset() {
        window.grecaptcha.reset()
      },
      getResponse() {
        return window.grecaptcha.getResponse(this.widget)
      },
      waitForRecaptchaLoad() {
        setTimeout(() => {
          if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render === 'undefined') {
            this.waitForRecaptchaLoad()
          } else {
            this.render()
          }
        }, 200)
      }
    },
    mounted() {
      this.waitForRecaptchaLoad()
    }
  }
</script>
