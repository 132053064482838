<template>
  <v-content>
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ t(text) }}
      <v-btn flat @click="snackbar = false">
        {{ t('Close') }}
      </v-btn>
    </v-snackbar>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>

          <recaptcha-v2 
            :callback="submitLoginFormIfCaptchaIsGood"
            ref="recaptcha"
          />
        
          <v-card class="elevation-12" v-if="!submitted && !stage2">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ t('Login') }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-form ref="form" class="text-xs-right" @submit.prevent="submitLoginForm">
              <v-card-text>
                <v-text-field :label="t('Email')" name="Email" prepend-icon="person"
                  :rules="[rules.required, rules.email]" v-model="email"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit">{{ t('Submit') }}</v-btn>
              </v-card-actions>
              <v-card-text class="d-flex justify-content-between mt-2">
                <router-link class="text-xs-left" to="/register">{{ t('Register now!') }}</router-link>
                <router-link class="text-xs-right" to="/login">{{ t('Login') }}</router-link>
              </v-card-text>
            </v-form>
          </v-card>

          <v-card class="elevation-12" v-else-if="submitted && !stage2">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ t('Login') }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <p>{{ t('Please check your email') }} {{ email }} {{ t('to verify your account') }}</p>
            </v-card-text>
          </v-card>
          
          <v-card class="elevation-12" v-else>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ t('Login') }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-form ref="form2" class="text-xs-right" @submit.prevent="submitLoginFormStage2">
              <v-card-text>
                <v-text-field :label="t('Password')" name="password" prepend-icon="lock" type="password"
                  v-model="password" :rules="[rules.required, rules.counter]"></v-text-field>
                <v-text-field :label="('Repeat password')" name="password" prepend-icon="lock" type="password"
                  v-model="repeatedPassword" :rules="[rules.required, rules.repeatPassword]"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit">{{ t('Submit') }}</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import axios from "axios";
import RecaptchaV2 from '../../components/Recaptcha/RecaptchaV2.vue';

export default {
  name: "LoginForgotPassword",
  components: {RecaptchaV2},
  data() {
    return {
      snackbar: false,
      text: 'Oops... Something went wrong',
      timeout: 5000,
      title: 'Preliminary report',
      username: '',
      email: '',
      password: '',
      repeatedPassword: '',
      submitted: false,
      stage2: false,
      rules: {
        required: value => !!value || this.$translate.locale['Required.'],
        username: value => (value.length >= 4 && value.length <= 150) || this.$translate.locale['Username between the 4 - 150 characters'],
        counter: value => value.length >= 8 || this.$translate.locale['Min 8 characters'],
        repeatPassword: value => value === this.password || this.$translate.locale['Passwords do not match'],
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$translate.locale['Invalid e-mail.']
        },
      },
    }
  },
  methods: {
    // called when user enters his email and presses submit
    submitLoginForm() {
      if (!this.$refs.form.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true
        return;
      } 

      this.$refs.recaptcha.execute();
    },
    submitLoginFormIfCaptchaIsGood(token) {
      if (!this.$refs.form.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true
      } else {
        axios.post(`/api/password-reset/`, { recaptcha: token, email: this.email })
          .then((response) => {
            if (response.status === 200) {
              this.submitted = true;
            }
          }).catch((error) => {
            this.submitted = false;
            this.text = error.response.data.email[0];
            this.snackbar = true;
          });
      }

    },
    // called when user opens change password url from his email
    // and enters new password and presses submit
    submitLoginFormStage2() {
      let token = this.$route.params.token;

      if (!this.$refs.form2.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true
      } else {
        if (this.$route.params.token) {
          axios.post(`/api/password-reset/confirm/`, {
            token: token,
            password: this.password,
          })
            .then((response) => {
              if (response.status === 200) {
                this.text = "Successful";
                this.snackbar = true;
                this.$router.push('/login')
              }
            }).catch((error) => {
              if (error.response.data.detail) {
                this.text = response.data.detail;
              } else if (error.response.status == 400 && error.response.data.password) {
                this.text = error.response.data.password[0];
              } else {
                this.text = "Connection error";
              }

              this.snackbar = true;
            });
        } else {
          this.text = "Connection error";
          this.snackbar = true;
        }
      }
    }
  },
  beforeMount() {
    let token = this.$route.params.token;

    axios.post('/api/password-reset/validate_token/', { token })
      .then(response => {
        if (response.status === 200) {
          this.submitted = true;
          this.stage2 = true
        }
      })
  }
}
</script>

<style scoped></style>
